.nclicker {
    padding-top: 50px;
    padding-bottom: 100px;
}

.nclicker-d1 {
    width: 100%;
    display: flex;
    margin-bottom: 64px;
}

.nclicker-d1-left {
    width: 50%;
    display: flex;
}

.nclicker-d1-left > img {
    border-radius: 10px;
    border: 5px solid rgb(230, 150, 100);
    margin-left: auto;
    margin-right: auto;
}

.nclicker-d2 > img {
    border-radius: 10px;
    border: 5px solid rgb(230, 150, 100);
    margin-left: auto;
    margin-right: auto;
}

.nclicker-d1-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

#nc-app {
    width: 600px;
}

#nc-game {
    width: 600px;
}

.nclicker-dl-btn {
    cursor: pointer;
    background-color: rgb(230, 150, 100);
    color: white;
    width: 200px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 1350px) {

    .nclicker-d1 {
        display: flex;
        flex-direction: column;
    }

    .nclicker-d1-left {
        width: 100%;
    }

    #nc-app {
        max-width: 600px;
    }
    
    #nc-game {
        width: 600px;
    }

    .nclicker-d1-right {
        width: calc(100% - 32px);
        padding: 0 16px;
        margin-top: 32px;
        font-size: 0.8em;
    }

    .nclicker-d2 {
        font-size: 0.8em;
    }

}

@media only screen and (max-width: 650px) {

    #nc-app {
        max-width: 90%;
    }
    
    #nc-game {
        width: 90%;
    }

}